import { doc } from 'firebase/firestore'
import { firestore } from './firebase';

const allowDev = true;
const isDev = process.env.NODE_ENV === 'development';
const raffleId = '6CYc558pWkovZaWmjked';
const raffleRef = doc(firestore, "raffles", raffleId);
const numbersPhone = (['+526421175001','+526471275938','+526421494684']);
export const config = {
    filterLimit: undefined,
    defaultLimit: 100, //Limite por default en la seleccion de boletos
    searchLimit: 20, //Limite por default en la seleccion de boletos
    price: 25, // Precio por boleto
    packagePrices: {
        //1: 45 // 1 boleto cuesta 42
    },
    number: 41, // Numero de sorteo
    raffle_tittle: 'BRONCO SUPER EQUIPADA Y ACCESORIZADA ',
    raffle_tittle1: ' Ó  💵💵 EFECTIVO ',
    raffle_tittle2: 'FECHA: 29 DE SEPTIEMBRE DEL 2024',
    currency: 'MXN',
    raffleId,
    rafflePad: 5,
    isDev,
    raffleRef,
    sendToNumber: () => numbersPhone[Math.floor(Math.random() * numbersPhone.length)]
}
